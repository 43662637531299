<template>
    <uw-content title="商机" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.state == null ? 'primary' : 'text'" @click="search.state = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.state == 1 ? 'primary' : 'text'" @click="search.state = 1; TableSearch()">跟进</el-button>
            <el-button size="mini" :type="search.state == 2 ? 'primary' : 'text'" @click="search.state = 2; TableSearch()">成功</el-button>
            <el-button size="mini" :type="search.state == 4 ? 'warning' : 'text'" @click="search.state = 4; TableSearch()">搁置</el-button>
            <el-button size="mini" :type="search.state == 3 ? 'danger' : 'text'" @click="search.state = 3; TableSearch()">失败</el-button>
            <el-button size="mini" :type="search.state == 0 ? 'danger' : 'text'" @click="search.state = 0; TableSearch()">放弃</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button @click="$refs.drawerCreate.Open()" type="primary" size="mini">新建商机</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <!-- 主题检索 -->
                <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="主题检索"></el-input>

                <!-- 角色过滤 -->
                <el-select v-model="search.user.column" @change="TableSearch()" slot="prepend" size="mini" placeholder="角色过滤" clearable>
                    <el-option label="销售" value="user_sale_id"></el-option>
                    <el-option label="售前" value="user_presale_id"></el-option>
                    <el-option label="商务" value="user_affair_id"></el-option>
                    <el-option label="管理员" value="user_admin_id"></el-option>
                </el-select>

                <!-- 用户检索 -->
                <users-input v-model="search.user.value" @onChange="TableSearch" />

                <!-- 跟进日期查询 -->
                <el-date-picker v-model="search.updated_at" clearable @change="TableSearch" value-format="yyyy-MM-dd"  size="mini" type="daterange" range-separator="至" start-placeholder="跟进日期查询" end-placeholder="查询范围"></el-date-picker>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 跟单状态 -->
                    <vxe-column min-width="80" field="state" title="跟单状态">
                        <template #default="{ row }">
                            <el-tag size="mini" effect="dark" v-if="row.state == 0" type="danger">放弃</el-tag>
                            <el-tag size="mini" effect="dark" v-if="row.state == 1" type="primary">跟进</el-tag>
                            <el-tag size="mini" effect="dark" v-if="row.state == 2" type="success">成功</el-tag>
                            <el-tag size="mini" effect="dark" v-if="row.state == 3" type="danger">失败</el-tag>
                            <el-tag size="mini" effect="dark" v-if="row.state == 4" type="warning">搁置</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 跟单主题 -->
                    <vxe-column min-width="210" field="name" title="跟单主题" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerDrawer.Open(row.id)" :icon="row.files_id.length > 0 ? 'el-icon-paperclip' : ''">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 关联客户 -->
                    <vxe-column width="210" field="customer_id" title="关联客户" sortable>
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-office-building">{{ row.customer.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 预期金额 -->
                    <vxe-column width="120" field="sign_money" title="预期金额" sortable>
                        <template #default="{ row }">
                            ￥{{ row.sign_money.toLocaleString() }}
                        </template>
                    </vxe-column>

                    <!-- 工作进度 -->
                    <vxe-column min-width="240" field="progress_id" title="工作进度">
                        <template #default="{ row }">
                            <el-tag size="mini" class="w-margin-r-5" v-for="(item, idx) in row.progress.filter(v => v.value > 0)" :key="idx">{{ item.title }}</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 销售 -->
                    <vxe-column width="90" field="user_sale_id" title="销售" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-user" :underline="false">{{ row.user_sale.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 商务 -->
                    <vxe-column width="90" field="user_affair_id" title="商务" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-user" :underline="false">{{ row.user_affair.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 售前 -->
                    <vxe-column width="90" field="user_presale_id" title="售前" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-user" :underline="false">{{ row.user_presale.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 管理员 -->
                    <vxe-column width="90" field="user_admin_id" title="管理员">
                        <template #default="{ row }">
                            <el-link icon="el-icon-user" :underline="false">{{ row.user_admin.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 跟进时间 -->
                    <vxe-column width="140" field="updated_at" title="跟进时间" sortable></vxe-column>

                    <vxe-column width="45" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-dropdown trigger="click">
                                <el-link icon="el-icon-more" type="primary" :underline="false"></el-link>
                                <el-dropdown-menu slot="dropdown">

                                    <el-dropdown-item @click.native.stop="TableUpdateState(row.id, 1)">
                                        <el-link type="primary" :underline="false">跟进</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="TableUpdateState(row.id, 4)">
                                        <el-link type="warning" :underline="false">搁置</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="TableUpdateState(row.id, 3)">
                                        <el-link type="danger" :underline="false">失败</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="TableUpdateState(row.id, 0)">
                                        <el-link type="danger" :underline="false">放弃</el-link>
                                    </el-dropdown-item>

                                    <el-dropdown-item @click.native.stop="$refs.drawerUpdate.Open(row.id)" divided>
                                        <el-link type="primary" :underline="false">跟进/编辑</el-link>
                                    </el-dropdown-item>

                                    <el-dropdown-item @click.native.stop="TableDelete(row.id)" divided>
                                        <el-link type="danger" :underline="false">删除</el-link>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <crm-business-create ref="drawerCreate" @success="TableSearch" />
        <crm-business-update ref="drawerUpdate" @success="TableSearch" />
        <crm-business-drawer ref="drawerDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {

            loading: false,
            
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                state: null,
                name: null,
                updated_at: null,
                user: {
                    column: null,
                    value: null,
                }
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/crm/business/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['customer', 'user_sale', 'user_admin', 'user_affair', 'user_presale'],
                    hidden: ['describe']

                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.loading = true
            this.$http.post('/9api/sale-documentary/delete', { id: id }).then((rps) => {
                this.TableSearch()
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 状态变更
        |--------------------------------------------------
        |
        */

        TableUpdateState(id, state) {
            this.loading = true
            this.$http.post('/9api/sale-documentary/update-state', { id: id, state: state }).then((rps) => {
                this.TableSearch()
            })
        }
    }
}
</script>